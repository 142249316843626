<template>
  <div class="containerFlexColumn">
    <el-row :gutter="20" class="">
      <el-col :span="8">
        <div class="bg-purple border bcWhite padding10 borderRadius5">
          <img :src="img_home_order" class="total-icon" />
          <div class="total-title">今日订单总数</div>
          <div class="total-value">200</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="bg-purple-light border bcWhite padding10 borderRadius5">
          <img :src="img_home_today_amount" class="total-icon" />
          <div class="total-title">今日销售总额</div>
          <div class="total-value">￥5000.00</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="bg-purple border bcWhite padding10 borderRadius5">
          <img :src="img_home_yesterday_amount" class="total-icon" />
          <div class="total-title">昨日销售总额</div>
          <div class="total-value">￥5000.00</div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="marginTop20 bcWhite borderRadius10 border">
          <div class="padding20 borderBottom fWB">待处理事务</div>
          <div class="un-handle-content">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">待付款订单</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">已完成订单</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">待确认收货订单</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">待发货订单</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">新缺货登记</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">待处理退款申请</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">已发货订单</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">待处理退货订单</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="un-handle-item">
                  <span class="font-medium">广告位即将到期</span>
                  <span style="float: right" class="color-danger">(10)</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="overview-layout">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="borderRadius10 bcWhite border">
            <div class="padding20 borderBottom fWB">货品总览</div>
            <div style="padding: 40px">
              <el-row>
                <el-col :span="6" class="color-danger overview-item-value">100</el-col>
                <el-col :span="6" class="color-danger overview-item-value">400</el-col>
                <el-col :span="6" class="color-danger overview-item-value">50</el-col>
                <el-col :span="6" class="color-danger overview-item-value">500</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="6" class="overview-item-title">已下架</el-col>
                <el-col :span="6" class="overview-item-title">已上架</el-col>
                <el-col :span="6" class="overview-item-title">库存紧张</el-col>
                <el-col :span="6" class="overview-item-title">全部货品</el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="bcWhite borderRadius10 border">
            <div class="padding20 borderBottom fWB">用户总览</div>
            <div style="padding: 40px">
              <el-row>
                <el-col :span="6" class="color-danger overview-item-value">100</el-col>
                <el-col :span="6" class="color-danger overview-item-value">200</el-col>
                <el-col :span="6" class="color-danger overview-item-value">1000</el-col>
                <el-col :span="6" class="color-danger overview-item-value">5000</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="6" class="overview-item-title">今日新增</el-col>
                <el-col :span="6" class="overview-item-title">昨日新增</el-col>
                <el-col :span="6" class="overview-item-title">本月新增</el-col>
                <el-col :span="6" class="overview-item-title">会员总数</el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="marginTop20 bcWhite borderRadius10 border">
      <div class="padding20 borderBottom fWB">订单统计</div>
      <el-row>
        <el-col :span="4">
          <div style="padding: 20px">
            <div>
              <div style="color: #909399; font-size: 14px">本月订单总数</div>
              <div style="color: #606266; font-size: 24px; padding: 10px 0">10000</div>
              <div>
                <span class="color-success" style="font-size: 14px">+10%</span>
                <span style="color: #c0c4cc; font-size: 14px">同比上月</span>
              </div>
            </div>
            <div style="margin-top: 20px">
              <div style="color: #909399; font-size: 14px">本周订单总数</div>
              <div style="color: #606266; font-size: 24px; padding: 10px 0">1000</div>
              <div>
                <span class="color-danger" style="font-size: 14px">-10%</span>
                <span style="color: #c0c4cc; font-size: 14px">同比上周</span>
              </div>
            </div>
            <div style="margin-top: 20px">
              <div style="color: #909399; font-size: 14px">本月销售总额</div>
              <div style="color: #606266; font-size: 24px; padding: 10px 0">100000</div>
              <div>
                <span class="color-success" style="font-size: 14px">+10%</span>
                <span style="color: #c0c4cc; font-size: 14px">同比上月</span>
              </div>
            </div>
            <div style="margin-top: 20px">
              <div style="color: #909399; font-size: 14px">本周销售总额</div>
              <div style="color: #606266; font-size: 24px; padding: 10px 0">50000</div>
              <div>
                <span class="color-danger" style="font-size: 14px">-10%</span>
                <span style="color: #c0c4cc; font-size: 14px">同比上周</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="20">
          <div style="padding: 10px; border-left: 1px solid #dcdfe6">
            <div id="chartmainline" style="width: 100%; height: 300px"></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="18%" title="设置密码" append-to-body :show-close="false" :close-on-click-modal="false">
      <el-form ref="form" :model="form" class="login-form" auto-complete="on" label-position="left">
        <el-form-item label="密码">
          <el-input v-model="form.pwd"></el-input>
        </el-form-item>
        <el-button size="small" :loadiing="btnLoading" type="primary" @click="submitForm">提 交</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import img_home_order from "@/assets/home_order.png";
import img_home_today_amount from "@/assets/home_today_amount.png";
import img_home_yesterday_amount from "@/assets/home_yesterday_amount.png";
import { removeToken } from "@/utils/auth";
export default {
  name: "Dashboard",
  data() {
    return {
      img_home_order,
      img_home_today_amount,
      img_home_yesterday_amount,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      form: {
        pwd: "",
      },
      dialogVisible: false,
      btnLoading: false,
      optionline: {
        title: {
          text: "折线图堆叠",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["邮件营销", "联盟广告", "视频广告", "直接访问", "搜索引擎"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "邮件营销",
            type: "line",
            stack: "总量",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "联盟广告",
            type: "line",
            stack: "总量",
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: "视频广告",
            type: "line",
            stack: "总量",
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: "直接访问",
            type: "line",
            stack: "总量",
            data: [320, 332, 301, 334, 390, 330, 320],
          },
          {
            name: "搜索引擎",
            type: "line",
            stack: "总量",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
        ],
      },
      optionbar: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "直接访问",
            type: "bar",
            barWidth: "60%",
            data: [10, 52, 200, 334, 390, 330, 220],
          },
        ],
      },
    };
  },
  mounted() {
    this.isPwd();
  },
  methods: {
    isPwd() {
      if (this.userInfo.editPwd === 0) {
        this.dialogVisible = true;
      }
    },
    submitForm() {
      let pwd = this.form.pwd.trim(); // 先移除两端的空格
      if (pwd === "" || pwd === " ") {
        this.$message({
          message: "密码不能为空",
          type: "error",
        });
        return;
      }
      this.btnLoading = true;
      this.userInfo.editPwd = true;
      let url = `${this.baseUrls.user}/edit`;
      this.topsAjax.post(url, { userId: this.userInfo.userId, userName: this.userInfo.phonenumber, password: this.form.pwd, editPwd: 1 }).then((response) => {
        this.$message({
          message: "密码设置成功",
          type: "success",
        });
        this.btnLoading = false;
        this.dialogVisible = false;
        sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        removeToken();
        window.location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.total-layout {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex: 1 1 33.33%;
}

.total-frame {
  border: 1px solid #dcdfe6;
  padding: 20px;
  height: 100px;
  width: calc((100% - 50px) / 3);
  margin-right: 25px;

  &:last-of-type {
    margin-right: 0;
  }
}

.total-icon {
  color: #409eff;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
}

.un-handle-layout {
  margin-top: 20px;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #ebeef5;
  padding: 10px;
}

.overview-layout {
  margin-top: 20px;
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.statistics-layout {
  margin-top: 20px;
}
</style>
